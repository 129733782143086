



































































































































































































































































































.outer_content_page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .inner_header {
    background-color: white;
    padding: 0 30px;
  }
  .innner_content {
    flex: 2;
    overflow-y: scroll;
    height: 100%;
    background-color: white;
    padding: 20px;
  }
}
.game_icon {
  font-size: 55px;
  color: #1890ff;
}
.game_title {
  font-size: 18px;
  color: #1890ff;
}
